import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authentication from './authentication';
import consumer from './consumer';
import entities from './entities';
import fanstore from './fanstore';
import sponsor from './sponsor';
import groupModal from './groupModal';
import flow from './flow';
import fulfillment from './fulfillment';
import error from './error';

const reducer = combineReducers({
  consumer,
  error,
  authentication,
  entities,
  fanstore,
  sponsor,
  groupModal,
  flow,
  fulfillment,
  router: routerReducer
});

export default reducer;
