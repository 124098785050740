import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import isUserAuthenticated from './isUserAuthenticated';
import Loader from '../components/FanstoreLoader';

const Fanstore = lazy(() => import('./Fanstore'));
const ConsumerFlow = lazy(() => import('./Flow'));
const Fulfillment = lazy(() => import('./Fulfillment'));

const AuthFulfillment = isUserAuthenticated(Fulfillment);

const Router = () => (
  <>
    <Switch>
      <Redirect path="/store" exact to="/fanstore" />
      <Redirect path="/" exact to="/fanstore" />
      <Suspense fallback={<Loader />}>
        <Route
          path="/fanstore"
          exact
          render={props => <Fanstore {...props} />}
        />
        <Route
          path="/flow/:pageNumber?"
          render={props => <ConsumerFlow {...props} />}
        />
        <Route
          path="/gift-card-fulfillment/:tangoFulfillmentId"
          render={props => <AuthFulfillment {...props} />}
        />
      </Suspense>
    </Switch>
  </>
);

export default Router;
