export const convertToArrayOfRgbValues = hex =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));

const hexToRgba = (cssHexValue, opacity = 1) => {
  const joinedVals = convertToArrayOfRgbValues(cssHexValue).join();

  return `rgba(${joinedVals},${opacity})`;
};

export default hexToRgba;
