import { convertToArrayOfRgbValues } from './conversions';

const colors = {
  blue: '#2181ec',
  lightGray: '#f2f4f6'
};

export const contrastingBlackOrWhiteColor = hex => {
  const rgb = convertToArrayOfRgbValues(hex);

  const luminance = Math.round(
    (parseInt(rgb[0], 10) * 299 +
      parseInt(rgb[1], 10) * 587 +
      parseInt(rgb[2], 10) * 114) /
      1000
  );
  return luminance > 200 ? '#000000' : '#FFFFFF';
};

export default colors;
