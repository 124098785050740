import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkUserAuth as checkUserAuthAction } from '../actions/authentication';
import {
  accessTokenSelector,
  isAuthCheckedSelector
} from '../reducers/authentication';

const mapStateToProps = state => ({
  accessToken: accessTokenSelector(state),
  authChecked: isAuthCheckedSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkUserAuth: checkUserAuthAction
    },
    dispatch
  );

class AuthGate extends Component {
  static propTypes = {
    authChecked: PropTypes.bool.isRequired,
    checkUserAuth: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  };

  async componentDidMount() {
    const { checkUserAuth } = this.props;
    await checkUserAuth();
  }

  render = () => {
    const { authChecked, children } = this.props;
    return authChecked ? children : null;
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthGate);
