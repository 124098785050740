import TYPES from '../actions/index';
import colors from '../util/colors';

const initialState = {
  description: '',
  id: null,
  name: null,
  slug: null,
  colorfillHexCode: colors.blue,
  phrase:
    'Shop local with us for the best goods and services in your neighborhood'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FANSTORE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload.sponsor
      };
    case TYPES.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/**
 * Select Fanstore sponsor.
 * @param {Object} state
 * @return {Sponsor}
 */
export const sponsorSelector = state => state.sponsor || {};

export default reducer;
