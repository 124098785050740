import { handleActions } from 'redux-actions';
import TYPES from '../actions';
import * as consumerSelectors from './consumer';

const initialState = {
  loaded: false,
  storeProfile: {},
  cards: [],
  campaignAsset: {},
  discount: 0,
  offer: {},
  inventory: [],
  inventoryDisclaimers: [],
  subTotal: 0,
  total: 0,
  error: {
    consumer: false,
    engagement: false,
    campaign: false,
    message: '',
    code: ''
  },
  isLoading: false,
  email: '',
  firstName: '',
  lastName: '',
  consumerFlowAddedCard: {
    id: null,
    lastFour: null,
    firstSix: null,
    zipCode: null,
    cvv: null,
    expiration: null
  }
};

const reducer = handleActions(
  {
    [TYPES.FLOW_LOADED]: state => {
      return {
        ...state,
        loaded: true
      };
    },
    [TYPES.UPDATE_CONSUMER_FLOW_CARD]: (state, action) => {
      return {
        ...state,
        consumerFlowAddedCard: action.payload
      };
    },
    [TYPES.FLOW_CAMPAIGN_ERRORED]: (state, action) => {
      return {
        ...state,
        error: {
          code: action.payload,
          campaign: true
        }
      };
    },
    [TYPES.IS_LOADING]: (state, action) => ({
      ...state,
      isLoading: action.payload
    }),
    [TYPES.FETCH_STORE_PROFILE_SUCCESS]: (state, action) => ({
      ...state,
      storeProfile: action.payload.data
    }),
    [TYPES.FETCH_CONSUMER_CARDS_SUCCESS]: (state, action) => ({
      ...state,
      cards: action.payload.data
    }),
    [TYPES.FETCH_CAMPAIGN_ASSET_SUCCESS]: (state, action) => {
      const { storeProfile, ...campaignAsset } = action.payload.data;
      return {
        ...state,
        campaignAsset,
        storeProfile: storeProfile || state.storeProfile
      };
    },
    [TYPES.FETCH_INVENTORY_ITEMS_SUCCESS]: (state, action) => ({
      ...state,
      offer: action.payload.data.offer,
      discount: action.payload.data.discount,
      inventory: action.payload.data.inventory,
      subTotal: action.payload.data.subTotal,
      total: action.payload.data.total,
      inventoryDisclaimers: action.payload.data.inventoryDisclaimers
    }),
    [TYPES.VALIDATE_EMAIL]: state => ({ ...state }),
    [TYPES.CREATE_ENGAGEMENT]: state => ({ ...state }),
    [TYPES.CREATE_ENGAGEMENT_SUCCESS]: (state, action) => ({
      ...state,
      email: action.payload.data.email,
      firstName: action.payload.data.firstName,
      lastName: action.payload.data.lastName,
      error: {}
    }),
    [TYPES.REGISTER_EMAIL_ERROR]: state => ({
      ...state,
      error: { engagement: true }
    }),
    [TYPES.CREATE_CONSUMER]: state => ({
      ...state
    }),
    [TYPES.SUBMIT_CONSUMER_FLOW_SUCCESS]: state => ({
      ...state,
      error: {}
    }),
    [TYPES.SUBMIT_CONSUMER_FLOW_FAIL]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [TYPES.CONSUMER_PURCHASE]: state => ({
      ...state
    }),
    [TYPES.CONSUMER_PURCHASE_SUCCESS]: (state, action) => ({
      ...state,
      inventory: action.payload.data.inventory
    }),
    [TYPES.CONSUMER_PURCHASE_FAIL]: (state, action) => {
      const {
        data: { error }
      } = action.error.response;
      const {
        request: { data }
      } = action.meta.previousAction.payload;
      const matchedCard = state.cards.find(
        card => card.id === data.consumerCardId && !card.disabled
      );
      if (error.code === 'CANCELED_CONSUMER_CARD' && matchedCard)
        matchedCard.disabled = true;
      return {
        ...state
      };
    },
    [TYPES.ONE_CLICK_PURCHASE_SUCCESS]: state => ({
      ...state,
      error: {}
    }),
    [TYPES.ONE_CLICK_PURCHASE_FAIL]: (state, action) => ({
      ...state,
      error: action.payload
    })
  },
  initialState
);

export const flowLoadedSelector = state => state.flow.loaded;

export const storeProfileSelector = state => state.flow.storeProfile;

export const campaignAssetSelector = state => state.flow.campaignAsset;

export const consumerCardSelector = state => state.flow.cards;

export const isLoadingSelector = state => state.flow.isLoading;

export const errorSelector = state => state.flow.error;

export const consumerFlowAddedCardSelector = state =>
  state.flow.consumerFlowAddedCard;

export const emailSelector = state => {
  const email = consumerSelectors.emailSelector(state);
  if (email) return email;

  return state.flow.email;
};

export const lastNameSelector = state => {
  const name = consumerSelectors.lastNameSelector(state);
  if (name) return name;
  return state.flow.lastName;
};

export const firstNameSelector = state => {
  const name = consumerSelectors.firstNameSelector(state);
  if (name) return name;
  return state.flow.firstName;
};
export const inventorySelector = state => {
  const { inventory } = state.flow;
  return inventory.length
    ? inventory.sort(a => (a.type === 'gift-card' ? -1 : 1))
    : [];
};

export const inventoryDisclaimerSelector = state =>
  state.flow.inventoryDisclaimers;

export const offerSelector = state => state.flow.offer;

export const discountSelector = state => state.flow.discount;

export const subTotalSelector = state => state.flow.subTotal;

export const totalSelector = state => state.flow.total;

export const acceptedCardSelector = state => {
  const { storeProfile = {} } = state.flow;

  const {
    acceptsVisa = true,
    acceptsAmericanExpress = true,
    acceptsMastercard = true
  } = storeProfile;

  const visa = acceptsVisa && 'Visa';
  const americanExpress = acceptsAmericanExpress && 'American Express';
  const mastercard = acceptsMastercard && 'Mastercard';

  return [visa, americanExpress, mastercard].filter(c => c);
};

export default reducer;
