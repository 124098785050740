import { normalize, schema } from 'normalizr';
import TYPES from '../actions/index';

const initialState = {
  inventory: [],
  groups: [],
  nationalRegion: {},
  localRegion: {},
  vendor: {}
};

/**
 * Represents Fanbank API's Fanstore_Inventory model.
 */
const Inventory = new schema.Entity('inventory');

/**
 * Represents Fanbank API's Fanstore_Group model.
 */
const Group = new schema.Entity('groups');

/**
 * Redux reducer for normalizing and storing entity data.
 * @param {Object} state
 * @param {Object} action
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FANSTORE_DATA_SUCCESS: {
      const data = normalize(action.payload, {
        inventory: [Inventory],
        groups: [Group]
      });
      return {
        ...state,
        ...data.entities,
        nationalRegion: {
          ...state.nationalRegion,
          ...action.payload.nationalRegion
        },
        localRegion: {
          ...state.localRegion,
          ...action.payload.localRegion
        },
        vendor: {
          ...state.vendor,
          ...action.payload.vendor
        }
      };
    }
    case TYPES.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/**
 * Select national region.
 * @param {Object} state
 * @return {Region}
 */
export const nationalRegionSelector = state =>
  state.entities.nationalRegion || {};

/**
 * Select local region.
 * @param {Object} state
 * @return {Region}
 */
export const localRegionSelector = state => state.entities.localRegion || {};

/** Select vendor.
 * @param {Object} state
 * @return {Vendor}
 */
export const vendorSelector = state => state.entities.vendor || {};

/**
 * Select group by id.
 * @param {Object} state
 * @param {number} groupId
 * @return {Group}
 */
export const groupSelector = (state, groupId) =>
  state.entities.groups[groupId] || {};

/**
 * Select inventory by id.
 * @param {Object} state
 * @param {number} inventoryId
 * @return {Inventory}
 */
export const inventorySelector = (state, inventoryId) =>
  state.entities.inventory[inventoryId] || {};

/**
 * Select groups by region, sorted by rank.
 * @param {Object} state
 * @param {Region} region
 * @return {Group[]}
 */
export const regionalGroupSelector = (state, region) => {
  const groups = (region.groups || [])
    .sort((a, b) => a.rank - b.rank)
    .map(({ id }) => groupSelector(state, id));
  return groups;
};

/**
 * Attach a vendorId key to each inventory in a group.
 * @param {Group} group
 * @return {Inventory[]}
 */
const mapVendorToGroupInventory = group =>
  group.inventory.map(inv => ({ ...inv, vendorId: group.vendorId }));

/**
 * Attach a vendorId key to an inventory item.
 * @param {Object} state
 * @param {Inventory} inventory
 * @return {Inventory}
 */
const mapVendorToInventoryItem = (state, inventory) => {
  const inventoryItem = inventorySelector(state, inventory.id);
  return { ...inventoryItem, vendorId: inventory.vendorId };
};

/**
 * Select inventory by group type.
 * @param {Object} state
 * @param {Region|Vendor} groupType
 */
const groupTypeInventorySelector = (state, groupType) => {
  const groups = regionalGroupSelector(state, groupType);
  const inventoryRanks = groups.map(group => mapVendorToGroupInventory(group));
  const inventory = []
    .concat(...inventoryRanks)
    .map(inv => mapVendorToInventoryItem(state, inv));
  return inventory;
};

/**
 * Get a list of inventory by group id, sorted by rank.
 * @param {Object} state
 * @param {number} groupId
 * @return {Inventory[]}
 */
export const inventoryByGroupSelector = (state, groupId) => {
  const group = groupSelector(state, groupId);
  return group.inventory
    ? mapVendorToGroupInventory(group)
        .sort((a, b) => a.rank - b.rank)
        .map(inv => mapVendorToInventoryItem(state, inv))
    : [];
};

/**
 * Select all national inventory, sorted alphabetically.
 * @param {Object} state
 * @return {Inventory[]}
 */
export const nationalInventorySelector = state => {
  const region = nationalRegionSelector(state);
  const inventory = groupTypeInventorySelector(state, region);
  return inventory.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
};

/**
 * Select all national groups, sorted by rank.
 * @param {Object} state
 * @return {Group[]}
 */
export const nationalGroupSelector = state => {
  const region = nationalRegionSelector(state);
  return regionalGroupSelector(state, region);
};

/**
 * Select all local inventory.
 * @param {Object} state
 * @return {Inventory[]}
 */
export const localInventorySelector = state => {
  const region = localRegionSelector(state);
  return groupTypeInventorySelector(state, region);
};

/**
 * Select the first vendor group
 * @param {Object} state
 * @return {Group[]}
 */
export const vendorGroupSelector = state => {
  const vendor = vendorSelector(state);
  const groups = regionalGroupSelector(state, vendor);
  return groups[0];
};

/**
 * Select all vendor inventory, sorted by rank.
 * @param {Object} state
 * @return {Inventory[]}
 */
export const vendorInventorySelector = state => {
  const group = vendorGroupSelector(state);
  return group ? inventoryByGroupSelector(state, group.id) : [];
};

/**
 * Select all local groups, sorted by rank.
 * @param {Object} state
 * @return {Group[]}
 */
export const localGroupsSelector = state => {
  const region = localRegionSelector(state);
  return regionalGroupSelector(state, region);
};

export default reducer;
