import { createRequest } from '../util/api';
import {
  checkingAuthStatus,
  logoutSuccess,
  authSuccess,
  authError
} from './index';

export const checkUserAuth = () => async dispatch => {
  const request = createRequest({ redirect401: false });
  try {
    dispatch(checkingAuthStatus());
    const res = await request('/authrequest.php');
    const auth = res.data;
    dispatch(authSuccess(auth));
    return auth;
  } catch (err) {
    dispatch(authError(err));
    return err;
  }
};

export const logout = () => async dispatch => {
  const request = createRequest();
  try {
    dispatch(checkingAuthStatus());
    const res = await request('/logout.php');
    dispatch(logoutSuccess());
    return res.data;
  } catch (err) {
    dispatch(logoutSuccess());
    return err;
  }
};
