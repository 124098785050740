import querystring from 'query-string';

/**
 * Redirect to the fanbank auth URL with an optional path
 * and an optional object of query params
 * @param {String} path
 * @param {Object} params
 * @return {String}
 */
const redirectTo = (path = '', params) => {
  const queryString = params ? `?${querystring.stringify(params)}` : '';
  const url = `${process.env.REACT_APP_AUTH_URL}${path}${queryString}`;
  return window.location.assign(url);
};

export default redirectTo;
