import TYPES from '../actions/index';

const initialState = {
  zipCode: '',
  firstName: '',
  lastName: '',
  email: '',
  earnedFancoins: 0,
  redeemedFancoins: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CONSUMER_DATA_SUCCESS:
      return action.payload;
    case TYPES.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const fancoinBalanceSelector = state =>
  (state.consumer.earnedFancoins - state.consumer.redeemedFancoins).toFixed(2);

export const firstNameSelector = state => state.consumer.firstName;

export const lastNameSelector = state => state.consumer.lastName;
export const emailSelector = state => state.consumer.email;

export default reducer;
