import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { accessTokenSelector } from '../reducers/authentication';
import redirectTo from '../util/redirectTo';

const mapStateToProps = state => ({
  isAuthenticated: Boolean(accessTokenSelector(state))
});

const isUserAuthenticated = ComposedComponent => {
  class Authenticate extends Component {
    static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired,
      match: PropTypes.instanceOf(Object).isRequired
    };

    componentDidMount = () => this.checkAndRedirect();

    componentDidUpdate = () => this.checkAndRedirect();

    checkAndRedirect() {
      const { isAuthenticated, match } = this.props;
      const url = `${window.location.origin}${match.url}`;
      if (!isAuthenticated) redirectTo('/login.php', { redirectUrl: url });
    }

    render() {
      const { isAuthenticated } = this.props;
      return isAuthenticated && <ComposedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps)(Authenticate);
};

export default isUserAuthenticated;
