import React from 'react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import AuthGate from './containers/AuthGate';
import ErrorBoundary from './containers/ErrorBoundary';
import Router from './containers/Router';
import history from './history';
import StoreProvider from './StoreProvider';
import theme from './styledTheme';

const App: React.FC = () => (
  <StoreProvider>
    <AuthGate>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <ConnectedRouter history={history}>
            <Route path="/" component={Router} />
          </ConnectedRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </AuthGate>
  </StoreProvider>
);

export default App;
