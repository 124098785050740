import React from 'react';
import * as Sentry from '@sentry/browser';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import Button from './Button';
import { ReactComponent as SadFace } from '../images/sad_face.svg';
import { mobileOnly } from '../styledTheme';

const ErrorPage = () => (
  <Grid padded centered>
    <HeaderRow>
      <ErrorSection>
        <Header textAlign="left" as="h1">
          Oops!
          <Header.Subheader>
            There was a problem loading your information
          </Header.Subheader>
        </Header>
        <CrashButton
          onClick={() => Sentry.lastEventId() && Sentry.showReportDialog()}
        >
          Submit Crash Report
        </CrashButton>
        <SadFace />
      </ErrorSection>
    </HeaderRow>
    <Grid.Row>
      <SupportText>
        We have been notified. If this problem persists, please contact us at
        <a href="mailto:support@fanbank.com<"> support@fanbank.com</a>.
      </SupportText>
    </Grid.Row>
  </Grid>
);

export default ErrorPage;

// Styles
const HeaderRow = styled(Grid.Row)`
  background-color: #f1f5f9;
  height: 400px;
  position: relative;
`;

const CrashButton = styled(Button)`
  float: left;
  margin-top: 23px !important;
  margin-left: 5px !important;
`;

// FIXME: Refactor to get rid of !important css declarations
const SupportText = styled.h3`
  color: #7b8ba1 !important;
  margin-top: 15px !important;
`;

const ErrorSection = styled.div`
  max-width: 500px;
  position: absolute;
  bottom: 30px;
  ${mobileOnly`
    padding: 10px;
`};

  .ui.header {
    font-size: 60px !important;
    color: #7b8ba1 !important;
  }
  .sub.header {
    font-size: 35px !important;
    color: #7b8ba1 !important;
  }
  h3 {
    display: inline;
    font-weight: bold !important;
    float: left;
    padding-top: 20px;
    padding-left: 2%;
    color: #7b8ba1 !important;
  }
  img {
    float: right;
    margin-right: 15%;
    height: 100px;
  }
`;
