import { createBrowserHistory } from 'history';

function createHistory() {
  const browserHistory = createBrowserHistory();

  // Handle old hash URLs by parsing the hash and using the path to replace history. This also covers
  // the case where S3 is redirecting to index.html with a hash.
  // https://stackoverflow.com/a/34958026/651983
  if (
    browserHistory.location.pathname === '/' &&
    browserHistory.location.hash
  ) {
    const path = (/#(\/.*)$/.exec(browserHistory.location.hash) || [])[1];
    if (path) browserHistory.replace(path);
  }

  return browserHistory;
}

const history = createHistory();

export default history;
