import { handleActions, combineActions } from 'redux-actions';
import TYPES, { fulfillmentDataSuccess, fulfillmentDataFail } from '../actions';

const initialState = {
  storeProfile: {},
  campaignAsset: {},
  giftCard: {},
  order: {},
  isLoaded: false,
  error: {}
};

const reducer = handleActions(
  {
    [TYPES.FETCH_GIFT_CARD_FULFILLMENT_SUCCESS]: (state, { payload }) => ({
      ...state,
      giftCard: payload.data.giftCardItem,
      storeProfile: payload.data.store || {},
      campaignAsset: payload.data.campaign || {},
      order: payload.data.order
    }),
    [TYPES.CREATE_GIFT_CARD_ORDER_SUCCESS]: (state, { payload }) => ({
      ...state,
      order: payload.data,
      error: {}
    }),
    [combineActions(fulfillmentDataSuccess, fulfillmentDataFail)]: state => ({
      ...state,
      isLoaded: true
    }),
    [TYPES.CREATE_GIFT_CARD_ORDER_FAIL]: (state, action) => {
      const {
        data: { error }
      } = action.error.response;

      return {
        ...state,
        error,
        isLoaded: true
      };
    }
  },
  initialState
);

export const storeProfileSelector = state => state.fulfillment.storeProfile;
export const campaignAssetSelector = state => state.fulfillment.campaignAsset;
export const giftCardSelector = state => state.fulfillment.giftCard;
export const orderSelector = state => state.fulfillment.order;
export const errorSelector = state => state.fulfillment.error;

// The fulfillment page is considered loaded when a gift
// card is present and all network requests complete
export const isLoadedSelector = state => {
  const { isLoaded, giftCard } = state.fulfillment;
  return Boolean(giftCard.id && isLoaded);
};

export default reducer;
