import TYPES from '../actions/index';

const initialState = {
  accessToken: {
    id: null,
    userId: null,
    ttl: null,
    principalType: null
  },
  authChecked: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.AUTH_SUCCESS:
      return {
        accessToken: action.payload,
        authChecked: true
      };
    case TYPES.AUTH_ERROR:
    case TYPES.LOGOUT_SUCCESS:
      return {
        accessToken: initialState.accessToken,
        authChecked: true
      };
    default:
      return state;
  }
};

/**
 * Select the logged-in user's access token from state.
 * @param {Object} state Redux state
 * @return {string} Logged-in user's access token
 */
export const accessTokenSelector = state => state.authentication.accessToken.id;

/**
 * Select the logged-in user's id.
 * @param {Object} state
 * @return {Number} logged-in user's id
 */
export const userIdSelector = state => state.authentication.accessToken.userId;

/**
 * Determine if authentication has been checked.
 * @param {Object} state
 * @return {Boolean} has auth been checked
 */
export const isAuthCheckedSelector = state =>
  Boolean(state.authentication.authChecked);

export const isAuthenticatedSelector = state =>
  Boolean(
    state.authentication.accessToken.id &&
      state.authentication.accessToken.userId
  );

export default reducer;
