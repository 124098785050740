import axios from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';
import redirectTo from './redirectTo';

const API_CONSTANTS = {
  AUTH_URL: `${process.env.REACT_APP_AUTH_URL}`,
  API_URL: `${process.env.REACT_APP_API_URL}/api`,
  TOKEN_SERVICE_URL: process.env.REACT_APP_TOKEN_SERVICE_URL,
  TIMEOUT: 5000
};

export const authRedirect = (err, redirect401 = '/login.php') =>
  err.response && err.response.status === 401 && redirect401
    ? redirectTo(redirect401)
    : Promise.reject(err);

export const createRequest = ({ redirect401 = '/login.php' } = {}) => {
  const request = axios.create({
    baseURL: API_CONSTANTS.AUTH_URL,
    timeout: API_CONSTANTS.TIMEOUT,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
  });

  // Add an interceptor to redirect 401 errors
  request.interceptors.response.use(
    res => res,
    err => authRedirect(err, redirect401)
  );

  return request;
};

export const createAPIRequest = (
  token,
  { redirect401 = '/login.php' } = {}
) => {
  const request = axios.create({
    baseURL: API_CONSTANTS.API_URL,
    // timeout: API_CONSTANTS.TIMEOUT
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });

  // Add an interceptor to redirect 401 errors
  request.interceptors.response.use(
    res => res,
    err => authRedirect(err, redirect401)
  );

  return request;
};

export const apiClient = () =>
  axios.create({
    baseURL: API_CONSTANTS.API_URL,
    headers: { 'Content-Type': 'application/json' }
  });

export const authApiClient = () =>
  axios.create({
    baseURL: API_CONSTANTS.AUTH_URL,
    timeout: API_CONSTANTS.TIMEOUT,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
  });

export const tokenServiceClient = () =>
  axios.create({
    baseURL: API_CONSTANTS.TOKEN_SERVICE_URL,
    headers: { 'Content-Type': 'application/json' }
  });

export const reduxApiMiddleware = (
  axiosClient,
  authClient,
  tokenClient,
  tokenIdSelector,
  handleErr
) =>
  multiClientMiddleware({
    default: {
      client: axiosClient,
      options: {
        interceptors: {
          request: [
            ({ getState }, req) => {
              const tokenId = tokenIdSelector(getState());
              req.headers.Authorization = tokenId;
              return req;
            }
          ],
          response: [
            {
              error: (_, err) => handleErr(err)
            }
          ]
        }
      }
    },
    customError: {
      client: axiosClient,
      options: {
        interceptors: {
          request: [
            ({ getState }, req) => {
              const tokenId = tokenIdSelector(getState());
              req.headers.Authorization = tokenId;
              return req;
            }
          ],
          response: [
            {
              error: (_, err) => {
                err.global = false; // eslint-disable-line no-param-reassign
                return Promise.reject(err);
              }
            }
          ]
        },
        returnRejectedPromiseOnError: true
      }
    },
    auth: {
      client: authClient,
      options: { returnRejectedPromiseOnError: true }
    },
    tokenService: {
      client: tokenClient,
      options: {
        interceptors: {
          response: [
            {
              error: (_, err) => {
                err.global = false; // eslint-disable-line no-param-reassign
                return Promise.reject(err);
              }
            }
          ]
        },
        returnRejectedPromiseOnError: true
      }
    }
  });
