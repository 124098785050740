import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ErrorPage from '../components/ErrorPage';
import { globalErrorSelector } from '../reducers/error';

const mapStateToProps = state => ({
  globalError: globalErrorSelector(state)
});

export class ErrorBoundaryComponent extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    globalError: PropTypes.instanceOf(Object)
  };

  static defaultProps = {
    globalError: null
  };

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    const { globalError } = this.props;
    if (globalError) {
      Sentry.captureException(globalError.message);
    }
  }

  componentDidUpdate(prevProps) {
    const { globalError } = this.props;
    if (globalError !== prevProps.globalError) {
      Sentry.captureException(globalError.message);
    }
  }

  componentDidCatch(error) {
    // Capture and report internal rendering errors
    this.setState({ error });
    Sentry.captureException(error);
  }

  render() {
    const { error } = this.state;
    const { globalError, children } = this.props;
    if (error || globalError) return <ErrorPage />;
    return children;
  }
}

export default connect(mapStateToProps)(ErrorBoundaryComponent);
