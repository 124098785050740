import { Middleware } from 'redux';
import * as Sentry from '@sentry/browser';
import { emailSelector } from '../reducers/consumer';

const middleware: Middleware = store => next => action => {
  const state = store.getState();
  const email = emailSelector(state);

  if (email) Sentry.setUser({ email });

  Sentry.addBreadcrumb({
    category: 'redux-action',
    message: action.type
  });

  return next(action);
};

export default middleware;
