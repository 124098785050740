import { routerMiddleware } from 'react-router-redux';
import * as redux from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import facebookTracker from './facebookTracker';
import history from './history';
import reducer from './reducers';
import { accessTokenSelector } from './reducers/authentication';
import sentryMiddleware from './sentry/middleware';

import {
  apiClient,
  authApiClient,
  authRedirect,
  reduxApiMiddleware,
  tokenServiceClient
} from './util/api';

const createStore = (initialState?: object) => {
  const middleware = [
    thunkMiddleware,
    routerMiddleware(history),
    sentryMiddleware,
    reduxApiMiddleware(
      apiClient(),
      authApiClient(),
      tokenServiceClient(),
      accessTokenSelector,
      authRedirect
    ),
    facebookTracker
  ];

  return redux.createStore(
    reducer,
    initialState,
    composeWithDevTools(redux.applyMiddleware(...middleware))
  );
};

const store = createStore();

export default store;
