/* global document */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './fonts/typography.css';
import * as serviceWorker from './serviceWorker';
import setupAnalytics from './util/analytics';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENV
  });
}

setupAnalytics(process.env.REACT_APP_GA_TRACKING_ID);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
