import TYPES from '../actions/index';
import {
  inventoryByGroupSelector,
  nationalInventorySelector,
  vendorGroupSelector
} from './entities';

export const nationalGroup = {
  id: 'national',
  title: 'Welcome to the Fanstore',
  description: 'Get incredible discounts for being a fan of shopping local'
};

const initialState = {
  selected: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SELECT_GROUP:
      return { selected: action.payload };
    case TYPES.SELECT_ALL_NATIONAL:
      return { selected: nationalGroup.id };
    case TYPES.DESELECT_GROUP:
      return { selected: null };
    case TYPES.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/**
 * Determine if the modal should be open
 * @param {Object} state
 * @return {boolean}
 */
export const isOpenSelector = state => state.groupModal.selected !== null;

/**
 * Determine if the modal is currently displaying sponsor
 * @param {Object} state
 * @return {boolean}
 */
export const isDisplayingSponsorSelector = state => {
  const vendorGroup = vendorGroupSelector(state);
  return vendorGroup ? state.groupModal.selected === vendorGroup.id : false;
};

/**
 * Determine if the modal is currently displaying featured
 * @param {Object} state
 * @return {boolean}
 */
export const isDisplayingFeatureSelector = state =>
  state.groupModal.selected === nationalGroup.id;

/**
 * Select a group by the selected key in groupModal state.
 * @param {Object} state
 * @return {Group}
 */
export const selectedGroupSelector = state => {
  if (state.groupModal.selected === nationalGroup.id) {
    return nationalGroup;
  }
  return state.entities.groups[state.groupModal.selected] || {};
};

/**
 * Select a selected groups inventory
 * @param {Object} state
 * @return [Inventory]
 */
export const selectedGroupInventorySelector = state => {
  const { selected } = state.groupModal;
  if (selected === nationalGroup.id) {
    return nationalInventorySelector(state);
  }
  return selected ? inventoryByGroupSelector(state, selected) : [];
};

export default reducer;
