const initialState = {
  globalError: null
};

const reducer = (state = initialState, action) => {
  const { error } = action;

  if (!error || error.global === false) return state;

  return {
    globalError: error.response
      ? error.response.data.error
      : {
          statusCode: 500,
          message: 'Unexpected network error'
        }
  };
};

export const globalErrorSelector = state => state.error.globalError;

export default reducer;
