import TYPES from '../actions/index';

const initialState = {
  loaded: false
};

/**
 * Redux reducer for storing Fanstore UI and behavior state.
 * @param {Object} state
 * @param {Object} action
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FANSTORE_DATA_SUCCESS:
      return { loaded: true };
    case TYPES.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

/**
 * Has the fanstore been loaded?
 * @param {Object} state
 * @return {boolean}
 */
export const fanstoreLoadedSelector = state => Boolean(state.fanstore.loaded);

export default reducer;
