import React from 'react';
import PropTypes from 'prop-types';
import { Button as SemanticUIButton } from 'semantic-ui-react';
import styled from 'styled-components';
import colors from '../util/colors';

const BaseButton = styled(({ theme, ...rest }) => (
  <SemanticUIButton {...rest} />
))`
  &.ui.button {
    border-radius: 2rem;
    font-family: 'Open Sans';
  }
`;

const SponsorButton = styled(BaseButton).attrs(() => ({
  size: 'small'
}))`
  &.ui.button {
    background: ${({ background }) => background || colors.blue};
    color: ${({ font }) => font || '#fff'};
  }
`;

const RedeemButton = styled(BaseButton)`
  &.ui.button {
    background-color: #dde4ea;
    color: ${({ font }) => font || '#9DA5B7'};
  }
`;

const buttonTypeMap = {
  base: BaseButton,
  sponsor: SponsorButton,
  redeem: RedeemButton
};

const Button = ({ type, ...rest }) =>
  React.createElement(buttonTypeMap[type], rest);

Button.propTypes = {
  type: PropTypes.oneOf(Object.keys(buttonTypeMap))
};

Button.defaultProps = {
  type: 'base'
};

export default Button;
