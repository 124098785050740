import TYPES from './actions/index';

const fbTrack = (event, data) => {
  if (data) {
    window.fbq('track', event, data);
  } else {
    window.fbq('track', event);
  }
};

export default () => next => action => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case TYPES.CREATE_ENGAGEMENT_SUCCESS:
      fbTrack('InitiateCheckout');
      break;
    case TYPES.CONSUMER_PURCHASE_SUCCESS:
      fbTrack('Purchase', {
        value: action.payload.data.total,
        currency: 'USD'
      });
      break;
  }
  next(action);
};
