import { createAction } from 'redux-actions';
import querystring from 'query-string';

const TYPES = {
  PUSH: '@@router/CALL_HISTORY_METHOD',
  FETCHING_FANSTORE_DATA: 'FETCHING_FANSTORE_DATA',
  FANSTORE_DATA_SUCCESS: 'FANSTORE_DATA_SUCCESS',
  FANSTORE_DATA_ERROR: 'FANSTORE_DATA_ERROR',
  CHECKING_AUTH_STATUS: 'CHECKING_AUTH_STATUS',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_ERROR: 'AUTH_ERROR',
  FETCHING_CONSUMER_DATA: 'FETCHING_CONSUMER_DATA',
  CONSUMER_DATA_SUCCESS: 'CONSUMER_DATA_SUCCESS',
  CONSUMER_DATA_ERROR: 'CONSUMER_DATA_ERROR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  SELECT_GROUP: 'SELECT_GROUP',
  SELECT_ALL_NATIONAL: 'SELECT_ALL_NATIONAL',
  DESELECT_GROUP: 'DESELECT_GROUP',
  VALIDATE_EMAIL: 'VALIDATE_EMAIL',
  VALIDATE_EMAIL_SUCCESS: 'VALIDATE_EMAIL_SUCCESS',
  VALIDATE_EMAIL_FAIL: 'VALIDATE_EMAIL_FAIL',
  CREATE_ENGAGEMENT: 'CREATE_ENGAGEMENT',
  CREATE_ENGAGEMENT_SUCCESS: 'CREATE_ENGAGEMENT_SUCCESS',
  CREATE_ENGAGEMENT_FAIL: 'CREATE_ENGAGEMENT_FAIL',
  REGISTER_EMAIL_ERROR: 'REGISTER_EMAIL_ERROR',
  CREATE_CONSUMER: 'CREATE_CONSUMER',
  CREATE_CONSUMER_SUCCESS: 'CREATE_CONSUMER_SUCCESS',
  CREATE_CONSUMER_FAIL: 'CREATE_CONSUMER_FAIL',
  CREATE_CONSUMER_CARD: 'CREATE_CONSUMER_CARD',
  CREATE_CONSUMER_CARD_SUCCESS: 'CREATE_CONSUMER_CARD_SUCCESS',
  CREATE_CONSUMER_CARD_FAIL: 'CREATE_CONSUMER_CARD_FAIL',
  CONSUMER_PURCHASE: 'CONSUMER_PURCHASE',
  CONSUMER_PURCHASE_SUCCESS: 'CONSUMER_PURCHASE_SUCCESS',
  CONSUMER_PURCHASE_FAIL: 'CONSUMER_PURCHASE_FAIL',
  LOGIN_CONSUMER: 'LOGIN_CONSUMER',
  LOGIN_CONSUMER_SUCCESS: 'LOGIN_CONSUMER_SUCCESS',
  LOGIN_CONSUMER_FAIL: 'LOGIN_CONSUMER_FAIL',
  SUBMIT_CONSUMER_FLOW_FAIL: 'SUBMIT_CONSUMER_FLOW_FAIL',
  SUBMIT_CONSUMER_FLOW_SUCCESS: 'SUBMIT_CONSUMER_FLOW_SUCCESS',
  ONE_CLICK_PURCHASE_FAIL: 'ONE_CLICK_PURCHASE_FAIL',
  ONE_CLICK_PURCHASE_SUCCESS: 'ONE_CLICK_PURCHASE_SUCCESS',
  FETCH_STORE_PROFILE: 'FETCH_STORE_PROFILE',
  FETCH_STORE_PROFILE_SUCCESS: 'FETCH_STORE_PROFILE_SUCCESS',
  FETCH_STORE_PROFILE_FAIL: 'FETCH_STORE_PROFILE_FAIL',
  FETCH_CAMPAIGN_ASSET: 'FETCH_CAMPAIGN_ASSET',
  FETCH_CAMPAIGN_ASSET_SUCCESS: 'FETCH_CAMPAIGN_ASSET_SUCCESS',
  FETCH_CAMPAIGN_ASSET_FAIL: 'FETCH_CAMPAIGN_ASSET_FAIL',
  FETCH_INVENTORY_ITEMS: 'FETCH_INVENTORY_ITEMS',
  FETCH_INVENTORY_ITEMS_SUCCESS: 'FETCH_INVENTORY_ITEMS_SUCCESS',
  FETCH_INVENTORY_ITEMS_FAIL: 'FETCH_INVENTORY_ITEMS_FAIL',
  FETCH_CONSUMER_CARDS: 'FETCH_CONSUMER_CARDS',
  FETCH_CONSUMER_CARDS_SUCCESS: 'FETCH_CONSUMER_CARDS_SUCCESS',
  FETCH_CONSUMER_CARDS_FAIL: 'FETCH_CONSUMER_CARDS_FAIL',
  CREATE_CARD_TOKEN: 'CREATE_CARD_TOKEN',
  CREATE_CARD_TOKEN_SUCCESS: 'CREATE_CARD_TOKEN_SUCCESS',
  CREATE_CARD_TOKEN_FAIL: 'CREATE_CARD_TOKEN_FAIL',
  IS_LOADING: 'IS_LOADING',
  UPDATE_CONSUMER_FLOW_CARD: 'UPDATE_CONSUMER_FLOW_CARD',
  FLOW_LOADED: 'FLOW_LOADED',
  FLOW_CAMPAIGN_ERRORED: 'FLOW_CAMPAIGN_ERRORED',

  FULFILLMENT_DATA: 'FULFILLMENT_DATA',
  FULFILLMENT_DATA_SUCCESS: 'FULFILLMENT_DATA_SUCCESS',
  FULFILLMENT_DATA_FAIL: 'FULFILLMENT_DATA_FAIL',

  FETCH_GIFT_CARD_FULFILLMENT: 'FETCH_GIFT_CARD_FULFILLMENT',
  FETCH_GIFT_CARD_FULFILLMENT_SUCCESS: 'FETCH_GIFT_CARD_FULFILLMENT_SUCCESS',
  FETCH_GIFT_CARD_FULFILLMENT_FAIL: 'FETCH_GIFT_CARD_FULFILLMENT_FAIL',

  CREATE_GIFT_CARD_ORDER: 'CREATE_GIFT_CARD_ORDER',
  CREATE_GIFT_CARD_ORDER_SUCCESS: 'CREATE_GIFT_CARD_ORDER_SUCCESS',
  CREATE_GIFT_CARD_ORDER_FAIL: 'CREATE_GIFT_CARD_ORDER_FAIL'
};

// Fulfillment actions
export const fulfillmentData = createAction(TYPES.FULFILLMENT_DATA);
export const fulfillmentDataSuccess = createAction(
  TYPES.FULFILLMENT_DATA_SUCCESS
);
export const fulfillmentDataFail = createAction(TYPES.FULFILLMENT_DATA_FAIL);

export const fetchGiftCardFulfillment = createAction(
  TYPES.FETCH_GIFT_CARD_FULFILLMENT,
  tangoFulfillmentId => ({
    request: {
      url: `gift-card-fulfillments/${tangoFulfillmentId}`,
      params: {
        filter: {
          include: ['store', { campaign: 'content' }, 'giftCardItem', 'order']
        }
      }
    }
  })
);

export const createGiftCardOrder = createAction(
  TYPES.CREATE_GIFT_CARD_ORDER,
  tangoFulfillmentId => ({
    client: 'customError',
    request: {
      method: 'post',
      url: `/gift-card-fulfillments/${tangoFulfillmentId}/order`
    }
  })
);

// Flow actions
export const fetchStoreProfile = createAction(
  TYPES.FETCH_STORE_PROFILE,
  storeId => ({
    client: 'customError',
    request: {
      url: `store-profiles/${storeId}`
    }
  })
);

export const fetchCampaignAsset = createAction(
  TYPES.FETCH_CAMPAIGN_ASSET,
  campaignId => ({
    client: 'customError',
    request: {
      url: `campaign-assets/${campaignId}`,
      params: { filter: { include: ['content', 'storeProfile'] } }
    }
  })
);

export const fetchInventoryItems = createAction(
  TYPES.FETCH_INVENTORY_ITEMS,
  ({ inventoryIds, campaignId }) => ({
    client: 'customError',
    request: {
      method: 'post',
      url: 'consumer-purchases',
      data: { inventoryIds, campaignId }
    }
  })
);

export const fetchConsumerCards = createAction(
  TYPES.FETCH_CONSUMER_CARDS,
  () => ({
    client: 'customError',
    request: {
      method: 'get',
      url: 'consumer-cards'
    }
  })
);

export const createPurchase = createAction(
  TYPES.CONSUMER_PURCHASE,
  ({ inventoryIds, consumerCardId, campaignId, storeId, businessId }) => ({
    client: 'customError',
    request: {
      method: 'post',
      url: 'consumer-purchases/pay',
      data: { inventoryIds, consumerCardId, campaignId, storeId, businessId }
    }
  })
);

export const validateEmail = createAction(TYPES.VALIDATE_EMAIL, email => ({
  client: 'customError',
  request: {
    method: 'post',
    url: 'consumer-registrations/validation',
    data: { email }
  }
}));

export const createEngagement = createAction(TYPES.CREATE_ENGAGEMENT, data => ({
  client: 'customError',
  request: {
    method: 'post',
    url: 'engagements',
    data
  }
}));

export const registerEmailError = createAction(TYPES.REGISTER_EMAIL_ERROR);

export const submitConsumerFlowDataFail = createAction(
  TYPES.SUBMIT_CONSUMER_FLOW_FAIL
);
export const submitConsumerFlowDataSuccess = createAction(
  TYPES.SUBMIT_CONSUMER_FLOW_SUCCESS
);

export const oneClickPurchaseSuccess = createAction(
  TYPES.ONE_CLICK_PURCHASE_SUCCESS
);
export const oneClickPurchaseFail = createAction(TYPES.ONE_CLICK_PURCHASE_FAIL);

export const createConsumer = createAction(TYPES.CREATE_CONSUMER, data => ({
  client: 'customError',
  request: {
    method: 'post',
    url: `consumer-registrations`,
    data
  }
}));

export const createConsumerCard = createAction(
  TYPES.CREATE_CONSUMER_CARD,
  data => ({
    client: 'customError',
    request: {
      method: 'post',
      url: `consumer-cards`,
      data
    }
  })
);

export const loginConsumer = createAction(TYPES.LOGIN_CONSUMER, data => ({
  client: 'auth',
  request: {
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/user_login.php',
    data: querystring.stringify(data)
  }
}));

export const createCardToken = createAction(
  TYPES.CREATE_CARD_TOKEN,
  ({ number }) => ({
    client: 'tokenService',
    request: {
      method: 'post',
      url: 'card-tokens',
      data: { number }
    }
  })
);

export const isLoading = bool => ({
  type: TYPES.IS_LOADING,
  payload: bool
});

export const updateConsumerFlowCard = data => ({
  type: TYPES.UPDATE_CONSUMER_FLOW_CARD,
  payload: data
});

export const flowLoaded = () => ({
  type: TYPES.FLOW_LOADED
});

export const flowCampaignErrored = code => ({
  type: TYPES.FLOW_CAMPAIGN_ERRORED,
  payload: code
});

// Fanstore actions
export const fetchingFanstoreData = () => ({
  type: TYPES.FETCHING_FANSTORE_DATA
});

export const fanstoreDataSuccess = data => ({
  type: TYPES.FANSTORE_DATA_SUCCESS,
  payload: data
});

export const fanstoreDataError = err => ({
  type: TYPES.FANSTORE_DATA_ERROR,
  error: err
});

// Auth actions
export const checkingAuthStatus = () => ({
  type: TYPES.CHECKING_AUTH_STATUS
});

export const authSuccess = id => ({
  type: TYPES.AUTH_SUCCESS,
  payload: id
});

export const logoutSuccess = () => ({
  type: TYPES.LOGOUT_SUCCESS
});

export const authError = err => ({
  type: TYPES.AUTH_ERROR,
  error: err
});

// Consumer actions
export const fetchingConsumerData = () => ({
  type: TYPES.FETCHING_CONSUMER_DATA
});

export const consumerDataSuccess = consumer => ({
  type: TYPES.CONSUMER_DATA_SUCCESS,
  payload: consumer
});

export const consumerDataError = err => ({
  type: TYPES.CONSUMER_DATA_ERROR,
  error: err
});

// GroupModal Actions
export const selectGroup = id => ({
  type: TYPES.SELECT_GROUP,
  payload: id
});

export const selectAllNational = () => ({
  type: TYPES.SELECT_ALL_NATIONAL
});

export const deSelectGroup = () => ({
  type: TYPES.DESELECT_GROUP
});

export default TYPES;
